
  import {mapGetters, mapActions} from 'vuex'

  export default {

    mounted() {
      this.fetchByPage()
    },

    data() {
      return {
        activeFilter: false
      }
    },

    computed: {
      ...mapGetters('catalogue', ['pins', 'isLoading', 'biometricSearch'])
    },

    methods: {
      ...mapActions('catalogue', ['fetchByPage']),

      onFilterChanged(data) {
        this.activeFilter = data.active
      }
    }
  }
